<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';

import { useSiteStore } from '~/stores/site.store';
import usePrepareMobileNumber from '~/composables/usePrepareMobileNumber';
import ToggleSwitch from 'primevue/toggleswitch';
import GenericError from '~/components/user-interface/generic-error.vue';

const { $authService, $storageService } = useServices();
const { $t, $mobileNumberRegex } = useNuxtApp();
const site = useSiteStore();
const authStore = useAuthStore();
const errorCode: globalThis.Ref<string> = ref('');
const form = ref();

const prepareMobileNumber = usePrepareMobileNumber;

const countryCode = site.region;

const mobileNumber = computed(() =>
  prepareMobileNumber(form?.value?.data['username'])
);
const valid: globalThis.Ref<boolean> = ref(false);
const formLoading: globalThis.Ref<boolean> = ref(false);

enum ECommunicationChannel {
  NONE,
  SMS,
  EMAIL,
}

const enableEmailOption = computed(() =>
  site.getFeatureFlags.get('website.enableemail')
);

const otpMethod = enableEmailOption.value
  ? ref<ECommunicationChannel>(ECommunicationChannel.EMAIL)
  : ref<ECommunicationChannel>(ECommunicationChannel.SMS);

const navigateToSuccess = () => {
  $storageService.setCookie({
    key: 'un',
    data: mobileNumber.value,
    expiry: 7,
  });
  authStore.setPasswordResetMobileNumber(mobileNumber.value);
  site.activateModal('passwordResetStepTwo');
};

const updateSettings = () => {
  formLoading.value = true;
  $storageService.setCookie({
    key: 'un',
    data: mobileNumber.value,
    expiry: 7,
  });
  authStore.setPasswordResetMobileNumber(mobileNumber.value);
  $authService
    .passwordResetInit({
      login:
        mobileNumber.value ||
        prepareMobileNumber(form?.value?.data['username']),
      countryCode,
      contactPreferences: otpMethod.value,
    })
    .then((data) => {
      if (data?.isSuccessful) {
        // redirect to otp confirm page
        navigateToSuccess();
      } else {
        errorCode.value = data?.error?.code ?? 'general';
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};
</script>

<template>
  <!-- Reset password wizard -->
  <div class="p-3">
    <div class="field col-12">
      <div class="text-xs">
        <p class="px-1 mb-0 text-black dark:text-white">
          {{ $t('enter.account.number') }}
        </p>
      </div>
      <Vueform
        ref="form"
        class="py-2"
        :float-placeholders="true"
        :endpoint="false"
      >
        <template #empty>
          <TextElement
            name="username"
            input-type="number"
            :attrs="{ inputmode: 'numeric' }"
            :placeholder="$t('placeholder.primaryMobileNumber')"
            :floating="$t('primaryMobileNumber')"
            :rules="['required', $mobileNumberRegex]"
            :messages="{ regex: $t('formErrors.primaryMobileNumber') }"
            :debounce="250"
          >
            <template #addon-before>
              <div
                class="bg-light-50 dark:bg-dark-800 flex flex-row justify-center items-center border-r border-solid std-border-color w-full h-full px-2"
              >
                {{ site.getDialingCode }}
              </div>
            </template>
          </TextElement>
        </template>
      </Vueform>
      <div class="select-method" v-if="enableEmailOption">
        <p class="my-2 text-base-priority text-xs">
          {{ $t('select-preferred-communication-option') }}
        </p>
        <!-- Select otp method -->
        <div class="grid grid-cols-2 gap-2 pb-2">
          <div class="flex gap-2 text-xs items-center">
            <ToggleSwitch
              input-id="otp-mobileNumber"
              :model-value="otpMethod === ECommunicationChannel.SMS"
              @input="otpMethod = ECommunicationChannel.SMS"
            />
            <label class="mr-2 text-black dark:text-white">
              {{ $t('sms') }}
            </label>
          </div>

          <div class="flex gap-2 text-xs items-center">
            <ToggleSwitch
              input-id="otp-email"
              :model-value="otpMethod === ECommunicationChannel.EMAIL"
              @input="otpMethod = ECommunicationChannel.EMAIL"
            />
            <label class="mr-2 text-black dark:text-white">
              {{ $t('email') }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Error messaging -->
    <div class="field-col-12 mb-3 mt-1 pt-0 flex justify-center">
      <GenericError v-if="errorCode" class="max-w-72 mx-auto" state="danger">
        {{ $t(`jpc-update-password-otp-error-` + errorCode) }}
      </GenericError>
    </div>
    <Button
      class="w-full justify-center"
      :disabled="!(form?.validated && !form?.invalid)"
      @click="updateSettings()"
    >
      {{ $t('send-reset-code') }}
    </Button>
  </div>
  <LazyUserInterfaceGenericLoader v-if="formLoading" container />
</template>
